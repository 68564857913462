import "../styles/index.scss";
import "./utils/header";
import throttle from "lodash/throttle";

let isDesktop = window.innerWidth >= 992;
let swiper, swiper2;

window.onload = function() {
  swiper = new Swiper(".hero-carousel", {
    effect: "fade",
    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    pagination: {
      el: ".pagination-fill",
    },
  });
  swiper2 = new Swiper(".full-width-carousel", {
    slidesPerView: isDesktop ? 1.6 : 2.2,
    spaceBetween: 20,
    centeredSlides: true,
    pagination: {
      el: ".pagination-outline",
    },
    loop: true,
  });
};

let checkResize = throttle(() => {
  if (window.innerWidth >= 992 && isDesktop == false) {
    isDesktop = true;
    swiper2.params.slidesPerView = 1.6;
  } else if (window.innerWidth < 992 && isDesktop == true) {
    isDesktop = false;
    swiper2.params.slidesPerView = 2.2;
  }
}, 300);

window.onresize = checkResize;
