import throttle from "lodash/throttle";

let headerEl = document.getElementById("navigation-header");
let sticky = headerEl.clientHeight;
let burgerOpenBtnEl = document.getElementById("burger-menu-open");
let burgerCloseBtnEl = document.getElementById("burger-menu-close");
let burgerMenuEl = document.getElementById("burger-menu");
let fallbackEl = document.getElementById("fallback");

let checkScroll = throttle(() => {
  let viewportWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  if (viewportWidth < 992) return;

  let scrollPos = Math.round(window.scrollY || window.pageYOffset);

  if (scrollPos > sticky) {
    headerEl.classList.add("sticky");
  } else {
    headerEl.classList.remove("sticky");
  }
}, 200);

window.onscroll = checkScroll;

(function() {
  let currentPathname = location.pathname.split("/");
  currentPathname = currentPathname[currentPathname.length - 1];
  let navLinks = document.querySelectorAll("nav .navbar-desktop li a");

  if (currentPathname === "") {
    navLinks[0].parentNode.classList.add("active");
  } else {
    navLinks.forEach(item => {
      if (item.getAttribute("href").indexOf(currentPathname) !== -1) {
        item.parentNode.classList.add("active");
      } else {
        item.parentNode.classList.remove("active");
      }
    });
  }
})();

burgerOpenBtnEl.onclick = function() {
  burgerMenuEl.classList.add("visible");
  fallbackEl.style.display = "block";
};
burgerCloseBtnEl.onclick = function() {
  burgerMenuEl.classList.remove("visible");
  fallbackEl.style.display = "none";
};

document.addEventListener("click", function(event) {
  if (event.target == fallbackEl) {
    burgerMenuEl.classList.remove("visible");
    fallbackEl.style.display = "none";
  }
});
